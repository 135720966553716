import React from 'react'
import { Form, Input } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'
import { useDebouncedValidation } from 'components/useDebouncedValidation/useDebouncedValidation'

export const PoolField = ({ index, form, caseName, standaloneFieldsValidation }) => {
  const debouncedValidation = useDebouncedValidation(standaloneFieldsValidation)
  return (
    <Form.Item
      label="Pool"
      name={[index, 'pool', 'value']}
      rules={[
        { required: true },
        {
          validator: () => serverValidator('pool', form, caseName, index),
        },
      ]}
    >
      <Input style={{ width: 90 }} onChange={(e) => debouncedValidation(e.target.value)} />
    </Form.Item>
  )
}
