import React from 'react'
import { Form, Select } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const RegionField = ({ index, regions, form, caseName, standaloneFieldsValidation }) => {
  return (
    <Form.Item
      label="Region"
      name={[index, 'region', 'value']}
      rules={[
        { required: true },
        {
          validator: () => serverValidator('region', form, caseName, index),
        },
      ]}
    >
      <Select style={{ width: 140 }} onChange={() => standaloneFieldsValidation()}>
        {regions.map((region) => (
          <Select.Option key={region}>{region}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
