import React from 'react'
import { Form, InputNumber } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'
import { useDebouncedValidation } from 'components/useDebouncedValidation/useDebouncedValidation'

export const PoolConcentrationField = ({ index, form, caseName, standaloneFieldsValidation }) => {
  const debouncedValidation = useDebouncedValidation(standaloneFieldsValidation)
  return (
    <Form.Item
      label="Pool concentration"
      name={[index, 'concentration', 'value']}
      rules={[
        { required: true },
        {
          validator: () => serverValidator('concentration', form, caseName, index),
        },
      ]}
    >
      <InputNumber style={{ width: 55 }} onChange={(e) => debouncedValidation(e)} />
    </Form.Item>
  )
}
