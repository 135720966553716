import React from 'react'
import { Form, Select } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const OriginalLabField = ({ index, labs, form, caseName, standaloneFieldsValidation }) => {
  return (
    <Form.Item
      label="Original lab"
      name={[index, 'original_lab', 'value']}
      rules={[
        { required: true },
        {
          validator: () => serverValidator('original_lab', form, caseName, index),
        },
      ]}
    >
      <Select style={{ width: 300 }} onChange={() => standaloneFieldsValidation()}>
        {labs.map((lab) => (
          <Select.Option key={lab}>{lab}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
