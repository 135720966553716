import React from 'react'
import { Form, Input } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'
import { useDebouncedValidation } from 'components/useDebouncedValidation/useDebouncedValidation'

export const SynopsisField = ({ caseName, isExisting, form, index, caseFieldsValidation }) => {
  const debouncedValidation = useDebouncedValidation(caseFieldsValidation)
  return (
    <Form.Item
      label="Synopsis"
      name={[caseName, 'synopsis', 'value']}
      rules={[
        {
          validator: () => serverValidator('synopsis', form, caseName, index),
        },
      ]}
    >
      <Input
        disabled={isExisting}
        style={{ width: 312 }}
        onChange={(e) => debouncedValidation(e.target.value)}
      />
    </Form.Item>
  )
}
