import React from 'react'
import styles from '../Forms.module.css'
import { Divider, Row } from 'antd'
import { SampleNameField } from '../Fields/SampleNameField'
import { ControlField } from '../Fields/ControlField'
import { PriorityField } from '../Fields/PriorityField'
import { SaveButton } from '../Fields/SaveButton'
import { ApplicationField } from '../Fields/ApplicationField'
import { OrganismField } from '../Fields/OrganismField'
import { OtherOrganismField } from '../Fields/OtherOrganismField'
import { CommentField } from '../Fields/CommentField'
import { ElutionBufferField } from '../Fields/ElutionBufferField'
import { ExtractionMethodField } from '../Fields/ExtractionMethodField'
import { PreProcessingMethodField } from '../Fields/PreProcessingMethodField'
import { PrimerField } from '../Fields/PrimerField'
import { RegionField } from '../Fields/RegionField'
import { SelectionCriteriaField } from '../Fields/SelectionCriteriaField'
import { CollectionDateField } from '../Fields/CollectionDateField'
import { OriginalLabField } from '../Fields/OriginalLabField'
import { ContainerField } from '../Fields/ContainerField'
import { ContainerNameField } from '../Fields/ContainerNameField'
import { WellPositionField } from '../Fields/WellPositionField'
import { VolumeField } from '../Fields/VolumeField'
import { ConcentrationSampleNGField } from '../Fields/ConcentrationSampleNGField'
import { QuantityNGField } from '../Fields/QuantityNGField'
import { DeleteButton } from '../Fields/DeleteButton'
import { ReferenceGenomeField } from '../Fields/ReferenceGenomeField'

export const SarsCov2Form = ({
  index,
  setSampleId,
  analysisType,
  controls,
  priorities,
  validateSample,
  setApplication,
  applicationTags,
  setIsOtherOrganism,
  form,
  organisms,
  isOtherOrganism,
  buffers,
  extractionMethods,
  prefillConditionalFields,
  setContainerNameRequirement,
  getAvailableContainersType,
  hasContainer,
  containerNameRequirement,
  isPlate,
  plateLayout,
  remove,
  sampleId,
  isBufferRequired,
  preprocessingMethods,
  primers,
  regions,
  selectionCriteria,
  labs,
  standaloneFieldsValidation,
}) => {
  return (
    <>
      <Row className={styles.row}>
        <SampleNameField
          index={index}
          isExisting={null}
          setSampleId={setSampleId}
          buildParentsObject={null}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        <ControlField
          index={index}
          isExisting={null}
          controls={controls}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        <PriorityField
          index={index}
          priorities={priorities}
          isExisting={null}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        <SaveButton saveValidate={validateSample} />
      </Row>
      <Row className={styles.row}>
        <ApplicationField
          index={index}
          isExisting={null}
          setApplication={setApplication}
          applicationTags={applicationTags}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        <OrganismField
          index={index}
          setIsOtherOrganism={setIsOtherOrganism}
          form={form}
          organisms={organisms}
          caseName={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        {isOtherOrganism && (
          <OtherOrganismField
            index={index}
            isOtherOrganism={isOtherOrganism}
            form={form}
            caseName={null}
            standaloneFieldsValidation={standaloneFieldsValidation}
          />
        )}
        <ReferenceGenomeField
          index={index}
          isExisting={null}
          ReferenceGenomesList={null}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
      </Row>
      <Row className={styles.row}>
        <CommentField
          index={index}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
      </Row>
      <Divider style={{ marginTop: '-2px' }} />
      <Row className={styles.row}>
        <ElutionBufferField
          index={index}
          isBufferRequired={isBufferRequired}
          application={null}
          buffers={buffers}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        <ExtractionMethodField
          index={index}
          extractionMethods={extractionMethods}
          form={form}
          caseName={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
      </Row>

      <PreProcessingMethodField
        index={index}
        preprocessingMethods={preprocessingMethods}
        form={form}
        caseName={null}
        standaloneFieldsValidation={standaloneFieldsValidation}
      />
      <PrimerField
        index={index}
        primers={primers}
        form={form}
        caseName={null}
        standaloneFieldsValidation={standaloneFieldsValidation}
      />
      <Row className={styles.row}>
        <RegionField
          index={index}
          regions={regions}
          form={form}
          caseName={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        <SelectionCriteriaField
          index={index}
          selectionCriteria={selectionCriteria}
          form={form}
          caseName={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        <CollectionDateField
          index={index}
          form={form}
          caseName={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        <OriginalLabField
          index={index}
          labs={labs}
          form={form}
          caseName={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
      </Row>
      <Row className={styles.row}>
        <ContainerField
          index={index}
          prefillConditionalFields={prefillConditionalFields}
          setContainerNameRequirement={setContainerNameRequirement}
          getAvailableContainersType={getAvailableContainersType}
          analysisType={analysisType}
          required={true}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        {hasContainer && (
          <>
            <ContainerNameField
              index={index}
              containerNameRequirement={containerNameRequirement}
              isRML={false}
              form={form}
              caseName={null}
              caseFieldsValidation={null}
              standaloneFieldsValidation={standaloneFieldsValidation}
            />
            {isPlate && (
              <WellPositionField
                index={index}
                plateLayout={plateLayout}
                isRML={false}
                form={form}
                caseName={null}
                caseFieldsValidation={null}
                standaloneFieldsValidation={standaloneFieldsValidation}
              />
            )}
            <VolumeField
              index={index}
              form={form}
              caseName={null}
              caseFieldsValidation={null}
              standaloneFieldsValidation={standaloneFieldsValidation}
            />
            <ConcentrationSampleNGField
              index={index}
              form={form}
              caseName={null}
              standaloneFieldsValidation={standaloneFieldsValidation}
            />
            <QuantityNGField
              index={index}
              form={form}
              caseName={null}
              standaloneFieldsValidation={standaloneFieldsValidation}
            />
          </>
        )}
      </Row>
      <DeleteButton remove={remove} index={index} sampleId={sampleId} />
    </>
  )
}
