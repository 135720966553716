import React from 'react'
import { Form, Select } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const WellPositionField = ({
  index,
  plateLayout,
  isRML,
  form,
  caseName,
  caseFieldsValidation,
  standaloneFieldsValidation,
}) => {
  const validationFunction = caseFieldsValidation || standaloneFieldsValidation
  return (
    <Form.Item
      label="Well"
      name={[index, isRML ? 'well_position_rml' : 'well_position', 'value']}
      rules={[
        { required: true },
        {
          validator: () =>
            serverValidator(isRML ? 'well_position_rml' : 'well_position', form, caseName, index),
        },
      ]}
    >
      <Select style={{ width: 70 }} onChange={() => validationFunction()}>
        {plateLayout().map((well) => (
          <Select.Option key={well} value={well}>
            {well}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
