import React from 'react'
import { Form, Input, Tooltip } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'
import { useDebouncedValidation } from 'components/useDebouncedValidation/useDebouncedValidation'

export const FormalinFixationHoursField = ({ index, form, caseName, caseFieldsValidation }) => {
  const debouncedValidation = useDebouncedValidation(caseFieldsValidation)

  return (
    <Tooltip title={'Formalin fixation hours'}>
      <Form.Item
        label="Fixation hrs"
        name={[index, 'formalin_fixation_time', 'value']}
        rules={[
          {
            validator: () => serverValidator('formalin_fixation_time', form, caseName, index),
          },
        ]}
      >
        <Input style={{ width: 35 }} onChange={(e) => debouncedValidation(e.target.value)} />
      </Form.Item>
    </Tooltip>
  )
}
