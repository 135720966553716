import React from 'react'
import { Form, InputNumber, Tooltip } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'
import { useDebouncedValidation } from 'components/useDebouncedValidation/useDebouncedValidation'

export const VolumeField = ({
  index,
  form,
  caseName,
  caseFieldsValidation,
  standaloneFieldsValidation,
}) => {
  const validationFn = standaloneFieldsValidation || caseFieldsValidation
  const debouncedValidation = useDebouncedValidation(validationFn)
  return (
    <Tooltip title={'Volume (μL). Must be between 20-130 μL'}>
      <Form.Item
        label="Volume (μL)"
        name={[index, 'volume', 'value']}
        rules={[
          {
            required: true,
            validator: () => serverValidator('volume', form, caseName, index),
          },
        ]}
      >
        <InputNumber style={{ width: 90 }} onChange={(e) => debouncedValidation(e)} />
      </Form.Item>
    </Tooltip>
  )
}
