import React from 'react'
import { Form, Select, Tooltip } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'
import { useDebouncedValidation } from 'components/useDebouncedValidation/useDebouncedValidation'

export const PhenotypeGroupsField = ({ index, form, caseName, caseFieldsValidation }) => {
  const debouncedValidation = useDebouncedValidation(caseFieldsValidation)
  return (
    <Tooltip title="Phenotype groups">
      <Form.Item
        label="Ph. groups"
        name={[index, 'phenotype_groups', 'value']}
        rules={[
          {
            validator: () => serverValidator('phenotype_groups', form, caseName, index),
          },
        ]}
      >
        <Select
          mode="tags"
          style={{ width: 210 }}
          onChange={(e) => debouncedValidation(e.target.value)}
        />
      </Form.Item>
    </Tooltip>
  )
}
