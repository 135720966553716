import React from 'react'
import { Form, Input } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'
import { useDebouncedValidation } from 'components/useDebouncedValidation/useDebouncedValidation'

export const CollectionDateField = ({ index, form, caseName, standaloneFieldsValidation }) => {
  const debouncedValidation = useDebouncedValidation(standaloneFieldsValidation)
  return (
    <Form.Item
      label="Collection date"
      name={[index, 'collection_date', 'value']}
      rules={[
        {
          required: true,
        },
        {
          validator: () => serverValidator('collection_date', form, caseName, index),
        },
      ]}
    >
      <Input placeholder="YYYY-MM-DD" onChange={(e) => debouncedValidation(e.target.value)} />
    </Form.Item>
  )
}
