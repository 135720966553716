import React from 'react'
import { Form, InputNumber } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'
import { useDebouncedValidation } from 'components/useDebouncedValidation/useDebouncedValidation'

export const IndexNumberField = ({
  index,
  prefillConditionalFields,
  indexSequence,
  form,
  caseName,
  standaloneFieldsValidation,
}) => {
  const debouncedValidation = useDebouncedValidation(standaloneFieldsValidation)
  return (
    <Form.Item
      label="Index number"
      name={[index, 'index_number', 'value']}
      rules={[
        { required: true },
        {
          validator: () => serverValidator('index_number', form, caseName, index),
        },
      ]}
    >
      <InputNumber
        style={{ width: 410 }}
        onChange={(e) => {
          prefillConditionalFields()
          debouncedValidation(e)
        }}
        addonAfter={<div style={{ width: 330 }}>{indexSequence}</div>}
      />
    </Form.Item>
  )
}
