import React from 'react'
import { Form, Switch, Tooltip } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'
import { useDebouncedValidation } from 'components/useDebouncedValidation/useDebouncedValidation'

export const ReqQCField = ({
  index,
  isExisting,
  form,
  caseName,
  caseFieldsValidation,
  standaloneFieldsValidation,
}) => {
  const validationFn = standaloneFieldsValidation || caseFieldsValidation
  const debouncedValidation = useDebouncedValidation(validationFn)
  return (
    <Tooltip title="Require quality control to continue">
      <Form.Item
        label="Req. QC"
        name={[index, 'require_qc_ok', 'value']}
        valuePropName="checked"
        rules={[
          {
            validator: () => serverValidator('require_qc_ok', form, caseName, index),
          },
        ]}
      >
        <Switch
          disabled={isExisting}
          data-testid={`require_qc_ok_${index}`}
          onChange={(e) => debouncedValidation(e)}
        />
      </Form.Item>
    </Tooltip>
  )
}
