import { useRef, useEffect } from 'react'
import { debounce } from 'lodash'

export const useDebouncedValidation = (validationFn, wait = 1000) => {
  const debouncedFn = useRef(debounce(validationFn, wait))

  useEffect(() => {
    debouncedFn.current = debounce(validationFn, wait)
    return () => debouncedFn.current.cancel()
  }, [validationFn, wait])

  return debouncedFn.current
}
