import React from 'react'
import { Form, Select } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const IndexField = ({
  index,
  prefillConditionalFields,
  indexSequences,
  form,
  caseName,
  standaloneFieldsValidation,
}) => {
  return (
    <Form.Item
      label="Index"
      name={[index, 'index', 'value']}
      rules={[
        { required: true },
        {
          validator: () => serverValidator('index', form, caseName, index),
        },
      ]}
    >
      <Select
        style={{ width: 340 }}
        onChange={() => {
          prefillConditionalFields()
          standaloneFieldsValidation()
        }}
      >
        {Object.keys(indexSequences).map((index) => (
          <Select.Option key={index}>{index}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
