import React from 'react'
import { Form, Select, Tooltip } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const PrimerField = ({ index, primers, form, caseName, standaloneFieldsValidation }) => {
  return (
    <Tooltip title={'Sequencing Platform and Primer Scheme'}>
      <Form.Item
        label="Platform/Primer"
        name={[index, 'primer', 'value']}
        rules={[
          { required: true },
          {
            validator: () => serverValidator('primer', form, caseName, index),
          },
        ]}
      >
        <Select style={{ width: 180 }} onChange={() => standaloneFieldsValidation()}>
          {primers.map((primer) => (
            <Select.Option key={primer}>{primer}</Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Tooltip>
  )
}
