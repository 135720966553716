import React from 'react'
import { Form, Radio } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'
import { useDebouncedValidation } from 'components/useDebouncedValidation/useDebouncedValidation'

export const StatusField = ({
  isExisting,
  index,
  carrierStatus,
  form,
  caseName,
  caseFieldsValidation,
  standaloneFieldsValidation,
}) => {
  const validationFn = standaloneFieldsValidation || caseFieldsValidation
  const debouncedValidation = useDebouncedValidation(validationFn)
  return (
    <Form.Item
      name={[index, 'status', 'value']}
      rules={[
        { required: !isExisting },
        {
          validator: () => serverValidator('status', form, caseName, index),
        },
      ]}
    >
      <Radio.Group
        size="small"
        data-testid={`status-${index}`}
        onChange={(e) => debouncedValidation(e.target.value)}
      >
        {carrierStatus.map(({ status }) => (
          <Radio.Button key={status} value={status}>
            {status}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item>
  )
}
