import React from 'react'
import { Form, Input } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'
import { useDebouncedValidation } from 'components/useDebouncedValidation/useDebouncedValidation'

export const SourceCommentField = ({
  index,
  form,
  caseName,
  caseFieldsValidation,
  standaloneFieldsValidation,
}) => {
  const validationFn = standaloneFieldsValidation || caseFieldsValidation
  const debouncedValidation = useDebouncedValidation(validationFn)
  return (
    <Form.Item
      label={'Which other source?'}
      name={[index, 'source_comment', 'value']}
      rules={[
        {
          required: true,
          validator: () => serverValidator('source_comment', form, caseName, index),
        },
      ]}
    >
      <Input
        placeholder={'Which other source?'}
        style={{ width: 245 }}
        onChange={(e) => debouncedValidation(e.target.value)}
      />
    </Form.Item>
  )
}
