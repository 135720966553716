import React from 'react'
import styles from '../../../pages/OrderForm/OrderForm.module.css'
import { Button, Row } from 'antd'
import { DeleteTwoTone } from '@ant-design/icons'
import { CaseNameField } from '../Fields/CaseNameField'
import { PriorityField } from '../Fields/PriorityField'
import { SaveButton } from '../Fields/SaveButton'
import { DeletePopConfirm } from 'components/DeletePopConfirm'
import { CohortsField } from '../Fields/CohortsField'
import { SynopsisField } from '../Fields/SynopsisField'

export const MIPRNACaseForm = ({
  caseName,
  isExisting,
  priorities,
  validateCase,
  remove,
  form,
  caseFieldsValidation,
}) => {
  return (
    <>
      <Row className={styles.formRow}>
        <CaseNameField
          caseName={caseName}
          isExisting={isExisting}
          form={form}
          caseFieldsValidation={caseFieldsValidation}
        />
        <PriorityField
          index={caseName}
          priorities={priorities}
          isExisting={isExisting}
          form={form}
          caseName={caseName}
          caseFieldsValidation={caseFieldsValidation}
          standaloneFieldsValidation={null}
        />
        <SaveButton saveValidate={validateCase} />
        <DeletePopConfirm
          itemType={'case'}
          action={remove}
          itemName={caseName}
          triggerComponent={<Button icon={<DeleteTwoTone />}>Case</Button>}
        />
      </Row>
      <Row className={styles.formRow}>
        <CohortsField
          caseName={caseName}
          isExisting={isExisting}
          form={form}
          index={caseName}
          caseFieldsValidation={caseFieldsValidation}
        />
        <SynopsisField
          caseName={caseName}
          isExisting={isExisting}
          form={form}
          index={caseName}
          caseFieldsValidation={caseFieldsValidation}
        />
      </Row>
    </>
  )
}
