import React from 'react'
import { Form, Input } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'
import { useDebouncedValidation } from 'components/useDebouncedValidation/useDebouncedValidation'

export const ContainerNameField = ({
  index,
  containerNameRequirement,
  isRML,
  form,
  caseName,
  caseFieldsValidation,
  standaloneFieldsValidation,
}) => {
  const validationFn = standaloneFieldsValidation || caseFieldsValidation
  const debouncedValidation = useDebouncedValidation(validationFn)
  return (
    <Form.Item
      label="Container name"
      name={[index, isRML ? 'rml_plate_name' : 'container_name', 'value']}
      rules={[
        {
          required: !isRML && containerNameRequirement,
        },
        {
          validator: () =>
            serverValidator(isRML ? 'rml_plate_name' : 'container_name', form, caseName, index),
        },
      ]}
    >
      <Input style={{ width: 207 }} onChange={(e) => debouncedValidation(e.target.value)} />
    </Form.Item>
  )
}
