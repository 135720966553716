import React from 'react'
import { Form, Switch } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'
import { useDebouncedValidation } from 'components/useDebouncedValidation/useDebouncedValidation'

export const TumourField = ({
  index,
  setIsTumour,
  isExisting,
  form,
  caseName,
  caseFieldsValidation,
  standaloneFieldsValidation,
}) => {
  const validationFn = standaloneFieldsValidation || caseFieldsValidation
  const debouncedValidation = useDebouncedValidation(validationFn)
  return (
    <Form.Item
      label="Is Tumour"
      name={[index, 'tumour', 'value']}
      valuePropName="checked"
      rules={[{ validator: () => serverValidator('tumour', form, caseName, index) }]}
    >
      <Switch
        onChange={(e) => {
          if (setIsTumour) {
            setIsTumour(e)
          }
          debouncedValidation(e)
        }}
        disabled={isExisting}
      />
    </Form.Item>
  )
}
