import React from 'react'
import { Form, Input } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'
import { useDebouncedValidation } from 'components/useDebouncedValidation/useDebouncedValidation'

export const CaseNameField = ({ caseName, isExisting, form, caseFieldsValidation }) => {
  const debouncedValidation = useDebouncedValidation(caseFieldsValidation)
  return (
    <Form.Item
      label="Case name"
      name={[caseName, 'name', 'value']}
      rules={[
        { required: true },
        {
          validator: () => serverValidator('name', form, caseName, null),
        },
      ]}
    >
      <Input
        minLength={2}
        disabled={isExisting}
        style={{ width: 240 }}
        onChange={(e) => debouncedValidation(e.target.value)}
      />
    </Form.Item>
  )
}
