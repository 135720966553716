import React from 'react'
import { Divider, Row } from 'antd'
import styles from '../Forms.module.css'
import { SampleNameField } from '../Fields/SampleNameField'
import { PriorityField } from '../Fields/PriorityField'
import { SaveButton } from '../Fields/SaveButton'
import { ReqQCField } from '../Fields/ReqQcField'
import { ApplicationField } from '../Fields/ApplicationField'
import { SexField } from '../Fields/SexField'
import { TumourField } from '../Fields/TumourField'
import { SubjectIdField } from '../Fields/SubjectIdField'
import { CommentField } from '../Fields/CommentField'
import { SourceField } from '../Fields/SourceField'
import { ContainerField } from '../Fields/ContainerField'
import { ContainerNameField } from '../Fields/ContainerNameField'
import { WellPositionField } from '../Fields/WellPositionField'
import { VolumeField } from '../Fields/VolumeField'
import { DeleteButton } from '../Fields/DeleteButton'
import { SourceCommentField } from '../Fields/SourceCommentField'
import { isPANPAZApplication } from 'pages/OrderForm/helpers'
import { BaitSetField } from '../Fields/BaitSetField'

export const PacBioForm = ({
  index,
  setSampleId,
  analysisType,
  priorities,
  validateSample,
  setApplication,
  applicationTags,
  form,
  prefillConditionalFields,
  setContainerNameRequirement,
  getAvailableContainersType,
  hasContainer,
  containerNameRequirement,
  isPlate,
  plateLayout,
  remove,
  sampleId,
  buildParentsObject,
  setIsTumour,
  application,
  options,
  isOtherSource,
  standaloneFieldsValidation,
}) => {
  return (
    <>
      <Row className={styles.row}>
        <SampleNameField
          index={index}
          isExisting={null}
          setSampleId={setSampleId}
          buildParentsObject={null}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        <PriorityField
          index={index}
          priorities={priorities}
          isExisting={null}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        <SaveButton saveValidate={validateSample} />
        <ReqQCField
          index={index}
          isExisting={null}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
      </Row>
      <Row className={styles.row}>
        <ApplicationField
          index={index}
          isExisting={null}
          setApplication={setApplication}
          applicationTags={applicationTags}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        {isPANPAZApplication(application) && (
          <BaitSetField
            index={index}
            isExisting={null}
            options={options}
            form={form}
            caseName={null}
            caseFieldsValidation={null}
            standaloneFieldsValidation={standaloneFieldsValidation}
          />
        )}
        <SexField
          index={index}
          isExisting={null}
          buildParentsObject={buildParentsObject}
          sampleId={sampleId}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        <TumourField
          index={index}
          setIsTumour={setIsTumour}
          isExisting={null}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        <SubjectIdField
          subjectId={null}
          required={false}
          index={index}
          isExisting={null}
          form={form}
          caseName={sampleId + 'fam'}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
      </Row>
      <Row className={styles.row}>
        <CommentField
          index={index}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
      </Row>
      <Divider style={{ marginTop: '-2px' }} />
      <Row className={styles.row}>
        <SourceField
          index={index}
          prefillConditionalFields={prefillConditionalFields}
          options={options}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        {isOtherSource && (
          <SourceCommentField
            index={index}
            form={form}
            caseName={null}
            caseFieldsValidation={null}
            standaloneFieldsValidation={standaloneFieldsValidation}
          />
        )}
      </Row>
      <Row className={styles.row}>
        <ContainerField
          index={index}
          prefillConditionalFields={prefillConditionalFields}
          setContainerNameRequirement={setContainerNameRequirement}
          getAvailableContainersType={getAvailableContainersType}
          analysisType={analysisType}
          required={true}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        {hasContainer && (
          <>
            <ContainerNameField
              index={index}
              containerNameRequirement={containerNameRequirement}
              isRML={false}
              form={form}
              caseName={null}
              caseFieldsValidation={null}
              standaloneFieldsValidation={standaloneFieldsValidation}
            />
            {isPlate && (
              <WellPositionField
                index={index}
                plateLayout={plateLayout}
                isRML={false}
                form={form}
                caseName={null}
                caseFieldsValidation={null}
                standaloneFieldsValidation={standaloneFieldsValidation}
              />
            )}
            <VolumeField
              index={index}
              form={form}
              caseName={null}
              caseFieldsValidation={null}
              standaloneFieldsValidation={standaloneFieldsValidation}
            />
          </>
        )}
      </Row>
      <DeleteButton remove={remove} index={index} sampleId={sampleId} />
    </>
  )
}
