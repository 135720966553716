import React from 'react'
import { Form, Select } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'
import { useDebouncedValidation } from 'components/useDebouncedValidation/useDebouncedValidation'

export const CohortsField = ({ caseName, isExisting, form, index, caseFieldsValidation }) => {
  const debouncedValidation = useDebouncedValidation(caseFieldsValidation)
  return (
    <Form.Item
      label="Cohorts"
      name={[caseName, 'cohorts', 'value']}
      rules={[
        {
          validator: () => serverValidator('cohorts', form, caseName, index),
        },
      ]}
    >
      <Select
        disabled={isExisting}
        mode="tags"
        style={{ width: 270 }}
        onChange={(e) => debouncedValidation(e.target.value)}
      />
    </Form.Item>
  )
}
