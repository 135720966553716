import React from 'react'
import { Form, Input } from 'antd'
import CopyButton from './CopyButton'
import { serverValidator } from '../../../pages/OrderForm/helpers'
import { useDebouncedValidation } from 'components/useDebouncedValidation/useDebouncedValidation'

export const SubjectIdField = ({
  subjectId,
  index,
  required,
  caseName,
  isExisting,
  form,
  caseFieldsValidation,
  standaloneFieldsValidation,
}) => {
  const validationFn = standaloneFieldsValidation || caseFieldsValidation
  const debouncedValidation = useDebouncedValidation(validationFn)
  return (
    <>
      <Form.Item
        label="Subject ID"
        name={[index, 'subject_id', 'value']}
        rules={[
          {
            required: required,
            message: 'Subject ID is required',
          },
          {
            validator: () => serverValidator('subject_id', form, caseName, index),
          },
        ]}
      >
        <Input
          style={{ width: 172 }}
          disabled={isExisting}
          onChange={(e) => debouncedValidation(e.target.value)}
        />
      </Form.Item>
      {isExisting && <CopyButton text={subjectId} />}
    </>
  )
}
