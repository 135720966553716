import React from 'react'
import { Form, Radio } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'
import { useDebouncedValidation } from 'components/useDebouncedValidation/useDebouncedValidation'

export const TissueBlockField = ({
  index,
  tissueBlockSize,
  form,
  caseName,
  caseFieldsValidation,
}) => {
  const debouncedValidation = useDebouncedValidation(caseFieldsValidation)
  return (
    <Form.Item
      label="Tissue block"
      name={[index, 'tissue_block_size', 'value']}
      rules={[
        {
          validator: () => serverValidator('tissue_block_size', form, caseName, index),
        },
      ]}
    >
      <Radio.Group size="small" onChange={(e) => debouncedValidation(e.target.value)}>
        {tissueBlockSize.map((status) => (
          <Radio.Button key={status} value={status}>
            {status}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item>
  )
}
