import React from 'react'
import { Form, Select } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const SelectionCriteriaField = ({
  index,
  selectionCriteria,
  form,
  caseName,
  standaloneFieldsValidation,
}) => {
  return (
    <Form.Item
      label="Selection criteria"
      name={[index, 'selection_criteria', 'value']}
      rules={[
        { required: true },
        {
          validator: () => serverValidator('selection_criteria', form, caseName, index),
        },
      ]}
    >
      <Select style={{ width: 230 }} onChange={() => standaloneFieldsValidation()}>
        {selectionCriteria.map((criterion) => (
          <Select.Option key={criterion}>{criterion}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
