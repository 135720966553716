import React from 'react'
import { Form, Input, Tooltip } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'
import { useDebouncedValidation } from 'components/useDebouncedValidation/useDebouncedValidation'

export const AgeField = ({
  index,
  form,
  caseName,
  caseFieldsValidation,
  standaloneFieldsValidation,
}) => {
  const validationFn = standaloneFieldsValidation || caseFieldsValidation
  const debouncedValidation = useDebouncedValidation(validationFn)
  return (
    <Tooltip title="Age at sampling">
      <Form.Item
        label="Age"
        name={[index, 'age_at_sampling', 'value']}
        rules={[
          {
            validator: () => serverValidator('age_at_sampling', form, caseName, index),
          },
        ]}
      >
        <Input style={{ width: 47 }} onChange={(e) => debouncedValidation(e.target.value)} />
      </Form.Item>
    </Tooltip>
  )
}
