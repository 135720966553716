import React from 'react'
import { Form, Select, Tag } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'
import { otherOrganism } from 'services/helpers/constants'

export const OrganismField = ({
  index,
  setIsOtherOrganism,
  form,
  organisms,
  caseName,
  standaloneFieldsValidation,
}) => {
  return (
    <Form.Item
      label="Organism"
      name={[index, 'organism', 'value']}
      rules={[
        { required: true },
        {
          validator: () => serverValidator('organism', form, caseName, index),
        },
      ]}
    >
      <Select
        style={{ width: 280 }}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option: any) =>
          option?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={(e) => {
          setIsOtherOrganism(e === otherOrganism)
          form.setFieldValue(
            ['samples', index, 'reference_genome', 'value'],
            organisms.find((organism) => organism.internal_id === e)?.reference_genome
          )
          standaloneFieldsValidation()
        }}
        options={organisms.map(({ internal_id, verified }) => ({
          label: (
            <div>
              {internal_id} <>{verified && <Tag color={'green'}>Verified</Tag>}</>
            </div>
          ),
          value: internal_id,
        }))}
      />
    </Form.Item>
  )
}
