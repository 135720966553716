import React from 'react'
import styles from '../Forms.module.css'
import { Divider, Row } from 'antd'
import { SampleNameField } from '../Fields/SampleNameField'
import { ControlField } from '../Fields/ControlField'
import { PriorityField } from '../Fields/PriorityField'
import { SaveButton } from '../Fields/SaveButton'
import { ApplicationField } from '../Fields/ApplicationField'
import { IndexField } from '../Fields/IndexField'
import { IndexNumberField } from '../Fields/IndexNumberField'
import { CommentField } from '../Fields/CommentField'
import { PoolField } from '../Fields/PoolField'
import { PoolConcentrationField } from '../Fields/PoolConcentrationField'
import { ContainerField } from '../Fields/ContainerField'
import { ContainerNameField } from '../Fields/ContainerNameField'
import { WellPositionField } from '../Fields/WellPositionField'
import { VolumeField } from '../Fields/VolumeField'
import { DeleteButton } from '../Fields/DeleteButton'
import { ConcentrationSampleNMField } from '../Fields/ConcentrationSampleNMField'

export const RmlFluffyForm = ({
  index,
  setSampleId,
  analysisType,
  controls,
  priorities,
  validateSample,
  setApplication,
  applicationTags,
  form,
  prefillConditionalFields,
  setContainerNameRequirement,
  getAvailableContainersType,
  hasContainer,
  containerNameRequirement,
  isPlate,
  plateLayout,
  remove,
  sampleId,
  indexSequences,
  indexSequence,
  hasIndex,
  standaloneFieldsValidation,
}) => {
  return (
    <>
      <Row className={styles.row}>
        <SampleNameField
          index={index}
          isExisting={null}
          setSampleId={setSampleId}
          buildParentsObject={null}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        <ControlField
          index={index}
          isExisting={null}
          controls={controls}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        <PriorityField
          index={index}
          priorities={priorities}
          isExisting={null}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        <SaveButton saveValidate={validateSample} />
      </Row>
      <Row className={styles.row}>
        <ApplicationField
          index={index}
          isExisting={null}
          setApplication={setApplication}
          applicationTags={applicationTags}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
      </Row>
      <Row className={styles.row}>
        <IndexField
          index={index}
          prefillConditionalFields={prefillConditionalFields}
          indexSequences={indexSequences}
          form={form}
          caseName={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        {hasIndex && (
          <IndexNumberField
            index={index}
            prefillConditionalFields={prefillConditionalFields}
            indexSequence={indexSequence}
            form={form}
            caseName={null}
            standaloneFieldsValidation={standaloneFieldsValidation}
          />
        )}
      </Row>
      <Row className={styles.row}>
        <CommentField
          index={index}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        <PoolField
          index={index}
          form={form}
          caseName={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        <PoolConcentrationField
          index={index}
          form={form}
          caseName={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
      </Row>
      <Divider style={{ marginTop: '-2px' }} />

      <Row className={styles.row}>
        <ContainerField
          index={index}
          prefillConditionalFields={prefillConditionalFields}
          setContainerNameRequirement={setContainerNameRequirement}
          getAvailableContainersType={getAvailableContainersType}
          analysisType={analysisType}
          required={false}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        {hasContainer && (
          <>
            <ContainerNameField
              index={index}
              containerNameRequirement={containerNameRequirement}
              isRML={true}
              form={form}
              caseName={null}
              caseFieldsValidation={null}
              standaloneFieldsValidation={standaloneFieldsValidation}
            />
            {isPlate && (
              <WellPositionField
                index={index}
                plateLayout={plateLayout}
                isRML={true}
                form={form}
                caseName={null}
                caseFieldsValidation={null}
                standaloneFieldsValidation={standaloneFieldsValidation}
              />
            )}
            <VolumeField
              index={index}
              form={form}
              caseName={null}
              caseFieldsValidation={null}
              standaloneFieldsValidation={standaloneFieldsValidation}
            />
            <ConcentrationSampleNMField
              index={index}
              form={form}
              caseName={null}
              standaloneFieldsValidation={standaloneFieldsValidation}
            />
          </>
        )}
      </Row>
      <DeleteButton remove={remove} index={index} sampleId={sampleId} />
    </>
  )
}
