import React from 'react'
import { serverValidator } from '../../../pages/OrderForm/helpers'
import { Form, Select } from 'antd'

export const ApplicationField = ({
  index,
  isExisting,
  setApplication,
  applicationTags,
  form,
  caseName,
  caseFieldsValidation,
  standaloneFieldsValidation,
}) => {
  const validationFunction = caseFieldsValidation || standaloneFieldsValidation
  return (
    <Form.Item
      label="Application tag"
      name={[index, 'application', 'value']}
      rules={[
        {
          required: true,
        },
        {
          validator: () => serverValidator('application', form, caseName, index),
        },
      ]}
    >
      <Select
        style={{ width: 170 }}
        disabled={isExisting}
        onChange={async (e) => {
          setApplication(e)
          validationFunction()
        }}
      >
        {applicationTags?.map((tag) => (
          <Select.Option key={tag} value={tag}>
            {tag}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
