import React from 'react'
import styles from '../Forms.module.css'
import { Divider, Row } from 'antd'
import { SampleNameField } from '../Fields/SampleNameField'
import { ControlField } from '../Fields/ControlField'
import { PriorityField } from '../Fields/PriorityField'
import { SaveButton } from '../Fields/SaveButton'
import { ApplicationField } from '../Fields/ApplicationField'
import { CommentField } from '../Fields/CommentField'
import { ElutionBufferField } from '../Fields/ElutionBufferField'
import { isBufferRequired } from 'pages/OrderForm/helpers'
import { ContainerField } from '../Fields/ContainerField'
import { ContainerNameField } from '../Fields/ContainerNameField'
import { WellPositionField } from '../Fields/WellPositionField'
import { VolumeField } from '../Fields/VolumeField'
import { DeleteButton } from '../Fields/DeleteButton'
import { ReqQCField } from '../Fields/ReqQcField'
import { SourceField } from '../Fields/SourceField'
import { QuantityNGField } from '../Fields/QuantityNGField'
import { SourceCommentField } from '../Fields/SourceCommentField'
import { ConcentrationField } from '../Fields/ConcentrationField'

export const MetagenomeWGSForm = ({
  index,
  setSampleId,
  analysisType,
  controls,
  priorities,
  validateSample,
  setApplication,
  applicationTags,
  form,
  buffers,
  prefillConditionalFields,
  setContainerNameRequirement,
  getAvailableContainersType,
  hasContainer,
  containerNameRequirement,
  isPlate,
  plateLayout,
  remove,
  sampleId,
  options,
  isOtherSource,
  skipReceptionControl,
  standaloneFieldsValidation,
}) => {
  return (
    <>
      <Row className={styles.row}>
        <SampleNameField
          index={index}
          isExisting={null}
          setSampleId={setSampleId}
          buildParentsObject={null}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        <ControlField
          index={index}
          isExisting={null}
          controls={controls}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        <PriorityField
          index={index}
          priorities={priorities}
          isExisting={null}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        <SaveButton saveValidate={validateSample} />
        <ReqQCField
          index={index}
          isExisting={null}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
      </Row>
      <Row className={styles.row}>
        <ApplicationField
          index={index}
          isExisting={null}
          setApplication={setApplication}
          applicationTags={applicationTags}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
      </Row>
      <Row className={styles.row}>
        <CommentField
          index={index}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
      </Row>
      <Divider style={{ marginTop: '-2px' }} />
      <Row className={styles.row}>
        <ElutionBufferField
          index={index}
          isBufferRequired={isBufferRequired}
          application={null}
          buffers={buffers}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
      </Row>
      <Row className={styles.row}>
        <SourceField
          index={index}
          prefillConditionalFields={prefillConditionalFields}
          options={options}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        {isOtherSource && (
          <SourceCommentField
            index={index}
            form={form}
            caseName={null}
            caseFieldsValidation={null}
            standaloneFieldsValidation={standaloneFieldsValidation}
          />
        )}
      </Row>
      <Row className={styles.row}>
        <ContainerField
          index={index}
          prefillConditionalFields={prefillConditionalFields}
          setContainerNameRequirement={setContainerNameRequirement}
          getAvailableContainersType={getAvailableContainersType}
          analysisType={analysisType}
          required={true}
          form={form}
          caseName={null}
          caseFieldsValidation={null}
          standaloneFieldsValidation={standaloneFieldsValidation}
        />
        {hasContainer && (
          <>
            <ContainerNameField
              index={index}
              containerNameRequirement={containerNameRequirement}
              isRML={false}
              form={form}
              caseName={null}
              caseFieldsValidation={null}
              standaloneFieldsValidation={standaloneFieldsValidation}
            />
            {isPlate && (
              <WellPositionField
                index={index}
                plateLayout={plateLayout}
                isRML={false}
                form={form}
                caseName={null}
                caseFieldsValidation={null}
                standaloneFieldsValidation={standaloneFieldsValidation}
              />
            )}
            <VolumeField
              index={index}
              form={form}
              caseName={null}
              caseFieldsValidation={null}
              standaloneFieldsValidation={standaloneFieldsValidation}
            />
            <ConcentrationField
              index={index}
              skipReceptionControl={skipReceptionControl}
              form={form}
              caseName={null}
              caseFieldsValidation={null}
              standaloneFieldsValidation={standaloneFieldsValidation}
            />
            <QuantityNGField
              index={index}
              form={form}
              caseName={null}
              standaloneFieldsValidation={standaloneFieldsValidation}
            />
          </>
        )}
      </Row>
      <DeleteButton remove={remove} index={index} sampleId={sampleId} />
    </>
  )
}
