import React from 'react'
import { Form, Input } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'
import { useDebouncedValidation } from 'components/useDebouncedValidation/useDebouncedValidation'

export const OtherOrganismField = ({
  index,
  isOtherOrganism,
  form,
  caseName,
  standaloneFieldsValidation,
}) => {
  const debouncedValidation = useDebouncedValidation(standaloneFieldsValidation)
  return (
    <Form.Item
      label={' Other organism'}
      name={[index, 'organism_other', 'value']}
      rules={[
        { required: !isOtherOrganism },
        {
          validator: () => serverValidator('organism_other', form, caseName, index),
        },
      ]}
    >
      <Input style={{ width: 150 }} onChange={(e) => debouncedValidation(e.target.value)} />
    </Form.Item>
  )
}
