import React from 'react'
import { Form, Select } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const PriorityField = ({
  index,
  priorities,
  isExisting,
  form,
  caseName,
  caseFieldsValidation,
  standaloneFieldsValidation,
}) => {
  const validationFunction = caseFieldsValidation || standaloneFieldsValidation

  return (
    <Form.Item
      label="Priority"
      name={[index, 'priority', 'value']}
      rules={[
        { required: !isExisting },
        {
          validator: () => serverValidator('priority', form, caseName, index),
        },
      ]}
    >
      <Select disabled={isExisting} style={{ width: 120 }} onChange={() => validationFunction()}>
        {priorities.map(({ value, text }) => (
          <Select.Option key={value} value={value}>
            {text}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
