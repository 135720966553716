import React from 'react'
import { Form, Select, Input } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'

export const ReferenceGenomeField = ({
  index,
  isExisting,
  ReferenceGenomesList,
  form,
  caseName,
  caseFieldsValidation,
  standaloneFieldsValidation,
}) => {
  const validationFunction = caseFieldsValidation || standaloneFieldsValidation
  return (
    <>
      {ReferenceGenomesList ? (
        <Form.Item
          label="Reference genome"
          name={[index, 'reference_genome', 'value']}
          rules={[
            { required: !isExisting },
            {
              validator: () => serverValidator('reference_genome', form, caseName, index),
            },
          ]}
        >
          <Select
            style={{ width: 150 }}
            disabled={isExisting}
            onChange={() => validationFunction()}
          >
            {ReferenceGenomesList.map(({ text, value }) => (
              <Select.Option key={value} value={value}>
                {text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ) : (
        <Form.Item
          label={
            <div>
              <a
                href="https://www.ncbi.nlm.nih.gov/genome/browse/#!/overview/"
                target="_blank"
                rel="noreferrer"
              >
                Reference genome
              </a>
            </div>
          }
          name={[index, 'reference_genome', 'value']}
          rules={[
            { required: true },
            {
              validator: () => serverValidator('reference_genome', form, caseName, index),
            },
          ]}
        >
          <Input
            style={{ width: 150 }}
            placeholder="e.g. NC_001928"
            onChange={() => validationFunction()}
          />
        </Form.Item>
      )}
    </>
  )
}
