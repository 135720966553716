import React from 'react'
import { Form, InputNumber, Tooltip } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'
import { useDebouncedValidation } from 'components/useDebouncedValidation/useDebouncedValidation'

export const ConcentrationSampleNMField = ({
  index,
  form,
  caseName,
  standaloneFieldsValidation,
}) => {
  const debouncedValidation = useDebouncedValidation(standaloneFieldsValidation)
  return (
    <Tooltip title={'Sample concentration (nM)'}>
      <Form.Item
        label="nM"
        name={[index, 'concentration_sample', 'value']}
        rules={[
          {
            validator: () => serverValidator('concentration_sample', form, caseName, index),
          },
        ]}
      >
        <InputNumber style={{ width: 65 }} onChange={(e) => debouncedValidation(e)} />
      </Form.Item>
    </Tooltip>
  )
}
