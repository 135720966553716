import React from 'react'
import { Form, Input } from 'antd'
import { serverValidator } from 'pages/OrderForm/helpers'
import { useDebouncedValidation } from 'components/useDebouncedValidation/useDebouncedValidation'

export const CommentField = ({
  index,
  form,
  caseName,
  caseFieldsValidation,
  standaloneFieldsValidation,
}) => {
  const validationFn = standaloneFieldsValidation || caseFieldsValidation
  const debouncedValidation = useDebouncedValidation(validationFn)
  return (
    <Form.Item
      label="Comment"
      name={[index, 'comment', 'value']}
      rules={[
        {
          validator: () => serverValidator('comment', form, caseName, index),
        },
      ]}
    >
      <Input style={{ width: 310 }} onChange={(e) => debouncedValidation(e.target.value)} />
    </Form.Item>
  )
}
